import { fetcher } from 'itty-fetcher';
import { useParams } from "react-router-dom";
export const time = () => {
    return Math.floor(Date.now() / 1000);
}
export const base_url = 'https://api.rf-w.in';//'http://127.0.0.1:8787';////process.env.REACT_APP_API_HOST;
/**
 * The log function is a helper function that logs the subject and data to the console.
 *
 *
 * @param subject Identify the source of a log entry
 * @param data Pass in the data that you want to log
 *
 * @return Undefined
 *
 * @docauthor Trelent
 */
export const log = (subject, data) => {
    let value_to_log = data;
    if (typeof data === "object") {
        value_to_log = {};
        for (const properyName of Object.getOwnPropertyNames(data)) {
            value_to_log[properyName] = data[properyName];
        }
    }
    if (process.env.NODE_ENV === 'development') {
        console.log(subject, data);
    } else {
        //todo add persistent logging and alerting
        console.log(subject, data);
    }
}
/**
 * The req function is a helper function that makes an HTTP request to the
 * specified URL. It takes three arguments: method, url, and data. The method
 * argument specifies the type of HTTP request to make (GET or POST). The url
 * argument specifies the URL for which you want to make a request. Finally, if
 * you pass in data as an argument then req will convert it into JSON and use it
 * as the body of your HTTP request. If no data is passed in then req will send
 * empty JSON object ({}) as its body instead of null (which would cause an error).
 *
 * @param method Specify the http method that you want to use
 * @param url Specify the url for the request
const get = async (url,data) =&gt;{
    return await req(&quot;get&quot;,url,data);
}

 const post = async (url, data) =&gt; {
     return await req(&quot;post&quot;, url, data);
 * @param data Pass data to the fetch function
 *
 * @return A promise
 *
 * @docauthor Trelent
 */
export const req = async (method, url, data) => {
    //const auth = useAuthUser()
    let contest_id = false
    if (url.includes("contest_id"))
        contest_id = url.split("contest_id=")[1].split("&")[0];
    if (!contest_id && data["contest_id"])
        contest_id = data["contest_id"];

    let auth = contest_id ? store.get(`c_${contest_id}_jwt`) : false;
    let shareEntry = contest_id ? store.get(`_rf3s_${contest_id}`) : false;
    if (shareEntry === 'spent')
        shareEntry = false;
    let fetch_options = {
        transformRequest(req) {
            req.headers['Authorization'] = auth;
            req.headers['share-entry'] = shareEntry;
            return req
        }
    }
    if (!url.startsWith("http"))
        fetch_options.base = base_url;
    let f = new fetcher(fetch_options);
    method = method.toUpperCase();
    let response = await f[method](url, data);
    
    try {//if response headers include share-saved then save to local storage
        //todo add response from headers once Kevin has updated  the library
        //console.log("response headders",response);
        //if (response.headers.get("share-saved"))
            //store.set(`_rf3s_${contest_id}`, 'spent');
    } catch (e) {
        //log("share-saved error", e);
    }
    return response;
}
/**
 * The form_obj function takes a form and returns an object of the form's data.
 *
 *
 * @param form Get the form data from the form
 *
 * @return A form object
 *
 * @docauthor Trelent
 */
export const form_obj = (form) => {
    const form_data = new FormData(form);
    const form_data_obj = {};
    form_data.forEach((value, key) => (form_data_obj[key] = value));
    return form_data_obj;
}

export const store = {
    //todo add expiration date for local storage (if value given store as obj and delete on get if expired)
    localStoreSupport: function () {
        try {
            return 'localStorage' in window && window['localStorage'] !== null;
        } catch (e) {
            return false;
        }
    },
    set: function (name, value, days) {
        let expires
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }
        else {
            expires = "";
        }
        if (this.localStoreSupport()) {
            localStorage.setItem(name, value);
        }
        else {
            document.cookie = name + "=" + value + expires + "; path=/";
        }
    },
    get: function (name) {
        if (this.localStoreSupport()) {
            let ret = localStorage.getItem(name);
            //console.log(typeof ret);
            switch (ret) {
                case 'true':
                    return true;
                case 'false':
                    return false;
                default:
                    return ret;
            }
        }
        else {
            // cookie fallback
            /*
             * after adding a cookie like
             * >> document.cookie = "bar=test; expires=Thu, 14 Jun 2018 13:05:38 GMT; path=/"
             * the value of document.cookie may look like
             * >> "foo=value; bar=test"
             */
            let nameEQ = name + "=";  // what we are looking for
            let ca = document.cookie.split(';');  // split into separate cookies
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];  // the current cookie
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);  // remove leading spaces
                if (c.indexOf(nameEQ) == 0) {  // if it is the searched cookie
                    let ret = c.substring(nameEQ.length, c.length);
                    // making "true" and "false" a boolean again.
                    switch (ret) {
                        case 'true':
                            return true;
                        case 'false':
                            return false;
                        default:
                            return ret;
                    }
                }
            }
            return null; // no cookie found
        }
    },
    del: function (name) {
        if (this.localStoreSupport()) {
            localStorage.removeItem(name);
        }
        else {
            this.set(name, "", -1);
        }
    }
}

