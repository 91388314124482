import TextField from "@mui/material/TextField";
import {
    EmailShareButton,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    TwitterIcon, LineIcon, TelegramIcon, LinkedinIcon, EmailIcon, RedditIcon
} from "react-share";
import {toast} from "react-toastify";
import {createElement, useEffect, useState} from "react";
import {req,store} from "../../../../CommonLibrary";
const start_entry_signed_in = async () => {
    ShareEntry();
}
export default function ShareEntry(props){
    const {entry_method,user_id,contest,ShowWhoForm} = props;
    const [shareButtons,setShareButtons] = useState([]);
    const [shareLink,setShareLink] = useState("Loading...");
    const getShareLink =async () => {
        if(!user_id)return;
        //todo if iframe url use that
        let host = window.location;
        let url = `/contest/contestant_get_share_link/?host=${encodeURIComponent(host)}&contest_id=${contest.contest_id}&emi=${entry_method.entry_method_id}`;
        let response = await req("GET",url);
        setShareLink(response.share_link);
        console.log("remove share loader",document.getElementById(`entry-method-loader-${entry_method.entry_method_id}`));
        document.getElementById(`entry-method-loader-${entry_method.entry_method_id}`).classList.remove("show");
    }
    const buildShareButtons = () => {
        let returnArray = [];
        let i=0;
        for(const s of entry_method.share_icons){
            if(s.name==='Facebook')
                returnArray.push(<FacebookShareButton url={shareLink} quote={entry_method.share_title}  key={i++}><FacebookIcon size={32} round={true} /></FacebookShareButton>);
            if(s.name==='Email')
                returnArray.push(<EmailShareButton url={shareLink} subject={entry_method.share_title} body={entry_method.share_body}  key={i++}><EmailIcon size={32} round={true} /></EmailShareButton>);
            if(s.name==='Reddit')
                returnArray.push(<RedditShareButton url={shareLink} subject={entry_method.share_title} body={entry_method.share_body}  key={i++}><RedditIcon size={32} round={true} /></RedditShareButton>);
            if(s.name==='Telegram')
                returnArray.push(<TelegramShareButton url={shareLink} title={entry_method.share_title}  key={i++}><TelegramIcon size={32} round={true} /></TelegramShareButton>);
            if(s.name==='Twitter')
                returnArray.push(<TwitterShareButton url={shareLink} title={entry_method.share_title} key={i++}><TwitterIcon size={32} round={true} /></TwitterShareButton>);
            if(s.name==='WhatsApp')
                returnArray.push(<WhatsappShareButton url={shareLink} title={entry_method.share_title} key={i++}><WhatsappIcon size={32} round={true} /></WhatsappShareButton>);
            if(s.name==='LinkedIn')
                returnArray.push(<LinkedinShareButton url={shareLink} title={entry_method.share_title} key={i++}><LinkedinIcon size={32} round={true} /></LinkedinShareButton>);
        }
        return returnArray;
    }
    const handleSharedLink = () => {
            const currentUrl = window.location.href;
            // Check if the URL contains a bookmark link
            if (currentUrl.includes("#_rf3s=")) {
              // Get the value of the bookmark link
              const bookmarkValue = currentUrl.split("#_rf3s=")[1];
          
              // Update the address bar by removing the bookmark link
              window.history.replaceState({}, document.title, currentUrl.split("#_rf3s=")[0]);
          
              // Store the bookmark value in local storage
              store.set("_rf3s_"+contest.contest_id, bookmarkValue);
            }
    }
    handleSharedLink();
    useEffect(() => {
        getShareLink();
    },[]);
    let shareIcons = buildShareButtons();
    useEffect(() => {
        setShareButtons(buildShareButtons());
    }, [shareLink]);

    //todo get who are you working with share link coming back
    if(!user_id){
        return ShowWhoForm(start_entry_signed_in);
    }else{
      
        const clickToCopy = (e) => {
            e.target.select();
            navigator.clipboard.writeText(e.target.value);
            toast("Copied", {
                toastId: 'copied',
                autoClose: 500
            });
        }
        try{
        document.getElementById(`entry-method-loader-${entry_method.entry_method_id}`).classList.remove("show");
        }catch(e){}
        return(<>

            <div className="entry-method-description"
                 dangerouslySetInnerHTML={{__html: entry_method.description}}/>
            <div className="entry-method-share">
                <TextField id="outlined-basic" className={'w-100'} onClick={clickToCopy} readOnly={true}
                           label="Click to copy" value={shareLink} variant="outlined"/>
                <div className={'text-center p-3 share-buttons'}>
                    {shareButtons}
                </div>
            </div>
            <div className={"how-share-works"}  dangerouslySetInnerHTML={{__html: entry_method.how_it_works}}></div>
        </>);
    }

}
