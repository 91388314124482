import React, {useEffect, useState} from "react";
import {
    useParams
} from "react-router-dom";
import {log, req, store} from "../../CommonLibrary";
import Countdown from "../ClassicContest/Components/Countdown";
import ContestTitle from "../ClassicContest/Components/ContestTitle";
import ContestImages from "../ClassicContest/Components/ContestImages";
import EntryMethods from "../ClassicContest/Components/EntryMethods";
import ContestTabs from "../ClassicContest/Components/ContestTabs";
import ContestScore from "../ClassicContest/Components/ContestScore";
import ContestBottomBar from "../ClassicContest/Components/ContestBottomBar";
import PreContestChecksGeo from "../ClassicContest/Components/PreContestChecksGeo";
import PreContestChecksAge from "./Components/PreContestChecksAge";
import {ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";
import useMessage from '@rottitime/react-hook-message-event';
import PreContestChecksTerms from "./Components/PreContestChecksTerms";
import PreContestPage from "./Components/PreContestPage";
import PostContestPage from "./Components/PostContestPage";
import PoweredByRf from "./Components/PoweredByRf";
import ContestNotAllowedInLocation from "./Components/ContestNotAllowedInLocation";
//for reviving messages in parent (if in iframe)

/*
Todo list

----add entry method limiter for paid plans
----add entry method show limiter
----add logged in contest holder nav (if not in frame)

----handle who are you and go   --mostly done  i need to add the popup
-----handle form entry methods e.g newsletter entry, share entry, etc


--pull details and rules via ajax (keeps contest object smaller) -- CHECK
--add element sorting
--handle foreign languages
--handle all entry types (post only, who are you and go, multiple entry (share)
--toggle login and logout - and auth

--get active  entries number (web sockets)
--handle time runs out on clock
--handle contest status update
 */
let loadingEntryMethods = false;
let windowFocused = false;

export default function ClassicContest(props) {
    let {id} = useParams();
    const [contestUrl, setContestUrl] = useState(window.location.href);//todo add postMessage from parent window
    const get_entry_methods = async () => {
        if(!props.contest)
            return;
        if (loadingEntryMethods) return;
        loadingEntryMethods = true;
        let url = "/contest/contestant_get_entry_methods/?contest_id=" + props.contest.contest_id;
        let result = await req("GET", url);
        let newContest = contest;
        newContest.entry_methods = result;
        SetContest(newContest)
        loadingEntryMethods = false;
    }

    const addCss = function (contest) {
        if (document.getElementById("cc-color")?.length && document.getElementById("cc-color")?.length)
            return false;
        const set_css_link = (url, id, prepend = false) => {
            document.getElementById(id)?.remove();
            let head = document.head;
            let link = document.createElement("link");
            link.type = "text/css";
            link.rel = "stylesheet";
            link.id = id;
            link.href = url;
            if (!prepend)
                head.appendChild(link);
            else
                head.prepend(link)
        }
        try {
            let css_url = `https://cdn-rf3.rewardsfuel.com/classic_contest/themes/styles/style_1.css`;//fallback
            let color_css_url = 'https://cdn-rf3.rewardsfuel.com/classic_contest_files/ClassicContestColorsLight.css'//color fallback (maybe no style)
            if(contest.layout?.use_color)
                color_css_url = `https://cdn-rf3.rewardsfuel.com/classic_contest/themes/colors/style_${contest.layout.use_color}.css`
            if (parseInt(contest.layout?.use_style) >0)
                css_url = `https://cdn-rf3.rewardsfuel.com/classic_contest/themes/styles/style_${contest.layout?.use_style}.css`
            if (parseInt(contest.layout?.use_style) ===0 && contest.layout?.custom_css)
                css_url = `https://cdn-rf3.rewardsfuel.com/${contest.contest_id}/style.css`
            if (contest.layout?.custom_colors)
                color_css_url = `https://cdn-rf3.rewardsfuel.com/${contest.contest_id}/color.css`
            set_css_link(css_url, 'cc-style');
            console.log("color_css_url", color_css_url)
            set_css_link(color_css_url, 'cc-color');//colors should be set after so anything from the main style sheets is overridden
            if (!contest.layout?.remove_bootstrap)
                set_css_link("https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css", 'bootstrap-css', true);
            //set_css_link("https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css", 'bootstrap-css', true);
        } catch (e) {
            log("error setting contest", e);
        }

    }
    const [contest, SetContest] = useState(null);
    let contestant_id = store.get(`c_${id}_jwt`);
    const [user_id, set_user_id] = useState(contestant_id);
    //todo add loading screen until all css has loaded
    const get_contest = async () => {
        let url = '/contest/get/?contest_id=' + id;
        if (contestant_id !== '') {
            url += '&jwt=' + store.get(`c_${id}_jwt`);
        }
        const data = await req("GET", url);
        document.title = data.contest_name;
        console.log("CONTEST", data);
        SetContest(data);
        addCss(data);
        setLocationAllowed(PreContestChecksGeo({contest: data}));
        let currentTime = new Date().getTime();
        if (data.start_time > currentTime)
            return setContestState("pre");
        if (data.end_time < currentTime)
            return setContestState("post");
        setContestState("live")
    }

    const add_css = (url, id) => {
        //todo remove main css or color css if previewing
        if (document.getElementById(id))
            document.getElementById(id).remove();
        let css = document.createElement('link');
        css.rel = 'stylesheet';
        css.media = 'all';
        css.id = id;
        css.href = url;
        document.getElementsByTagName('head')[0].appendChild(css);
        if (id === "css-preview" || id === "classic-contest-css") {
            document.getElementById("cc-style")?.remove();
        }
        if (id === "css-preview")
            document.getElementById("cc-style")?.remove();
    }
    const add_style_preview = (content, id) => {
        if (document.getElementById(id)) {
            document.getElementById(id).innerHTML = content;
            return;
        } else {
            let style = document.createElement("style");
            style.id = id;
            style.innerHTML = content;
            document.head.appendChild(style);
        }
    }
    const sortContestElements = (sortOrder, contestElements) => {
        // Sort the contest elements using the sort order
        contestElements.sort((a, b) => {
            // Get the sort names for each element
            const sortNameA = a.props.sortName;
            const sortNameB = b.props.sortName;

            // Find the index of each sort name in the sort order
            const indexA = sortOrder.indexOf(sortNameA);
            const indexB = sortOrder.indexOf(sortNameB);

            // Compare the indices to determine the sort order
            if (indexA < indexB) {
                return -1;
            }
            if (indexA > indexB) {
                return 1;
            }
            return 0;
        });

        return contestElements;
    };

// Example usage: sort the contest elements

    props = {contest, user_id,set_user_id, SetContest, ...props};

    const monitor_change_in_contestant = () => {
        setInterval(async () => {
            let stored_value = String(store.get(`c_${id}_jwt`));
            if (!stored_value)
                stored_value = '';
            let current_value = String(contestant_id);
            if (stored_value !== current_value) {
                if (stored_value === null)
                    stored_value = '';
                contestant_id = stored_value;
                set_user_id(contestant_id);
                get_contest();
            }
        }, 1000)
    }
    let mounted = false;
    useEffect(() => {
        if (mounted)
            return;
        mounted = true;
        document.title = 'Contest loading...';
        add_iframe_resizer();
        monitor_change_in_contestant();
        window.setInterval(() => {
            get_entry_methods();
        }, 15000);
        try {
            get_contest();
        } catch (e) {
            log("ClassicContest useEffect error", e);
        }
    }, []);
//todo wrap all these with a contest holder security checker
    const [isInEditor, setIsInEditor] = useState(false);
    useMessage('set-parent-url', (send, payload) => {
        setContestUrl(payload);//todo use in newsletter entry for redirection as well use it to cehck if contest is allowed in url
    });
    useMessage('iframe-in-editor', (send, payload) => {
        if (isInEditor)
            return
        //todo authenticate
        setIsInEditor(true);
        add_css("/ClassicContestEditorStyles.css", "cc-editor-styles");

    });

    useMessage('editor-focus', (send, payload) => {
        if (!isInEditor)
            return;
        let focusedClassName = "editor-focused";
        //remove class of focused on other elements
        [...document.getElementsByClassName(focusedClassName)].forEach(el => el.classList.remove(focusedClassName));
        if (payload.focus) {
            //scroll to item//add class of focused to this element
            const element = document.querySelector(payload.element);
            element.classList.add(focusedClassName);
            element.focus();
            element.scrollIntoView({behavior: 'smooth'});
        }
    })
    useMessage('update-preview', (send, payload) => {
        console.log("ClassicContest update-preview", payload);
        //if(!isInEditor)
        //return;
        collapseEntryMethods();
        SetContest(payload);
        
    })
    useMessage('update-css', (send, payload) => {
        addCss(payload);
    })
    useMessage('preview-color', (send, payload) => {
        let link = document.getElementById("cc-color");
        link.setAttribute("href", `https://cdn-rf3.rewardsfuel.com/classic_contest/themes/colors/style_${payload}.css`);
    })
    useMessage('preview-style', (send, payload) => {
        let link = document.getElementById("cc-style");
        link.setAttribute("href", `https://cdn-rf3.rewardsfuel.com/classic_contest/themes/styles/style_${payload}.css`);
    })
    const collapseEntryMethods = () => {
        const entryMethodHolders = document.querySelectorAll('.entry-method-holder');
        entryMethodHolders.forEach(element => {
            element.classList.remove('show');
        });
    };
    useMessage('set-tab-content', (send, payload) => {
        if (!isInEditor)
            return;
        try {
            if (!payload.body)
                document.getElementById(payload.tab).innerHTML = payload.content;
            else {
                console.log("ClassicContest set-tab-content", payload);
                document.getElementById("tab-content-" + payload.tab).innerHTML = payload.html;
            }
        } catch (e) {
            console.log("set tab content error", e);
        }
    })
    useMessage('set-css', (send, payload) => {
        if (!isInEditor)
            return;
        try {
            if (payload.remove_bs)
                document.getElementById("bootstrap-css")?.remove();
            if (payload.add_bs) {
                if (document.getElementById("bootstrap-css")?.length)
                    return;
                return add_css('https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css', 'bootstrap-css');
            }
            if (payload.preview_css) {
                //remove existing css
                try {
                    return add_style_preview(payload.preview_css, 'css-preview');
                } catch (e) {
                    console.log("no styles css");
                }
                //todo add revert handler
            }
            if (payload.preview_colors) {
                try {
                    add_style_preview(payload.preview_colors, 'color-style-preview');
                } catch (e) {
                    console.log("no colors css");
                }
            }
        } catch (e) {
            console.log("set-css error", e);
        }
    });
    useMessage('set-contest-state', (send, payload) => {
        setContestState(payload);
    });
    useMessage('set-preview-style', (send, payload) => {
        if (!isInEditor)
            return;
        //remove preview styles
        if (document.getElementById("css-preview"))
            document.getElementById("css-preview").remove();
        add_css(payload.url, 'classic-contest-css');
    });
    useMessage('set-preview-color', (send, payload) => {
        if (!isInEditor)
            return;
        //remove preview styles
        if (document.getElementById("color-style-preview"))
            document.getElementById("color-style-preview").remove();
        add_css(payload.url, 'color-style-preview');
    });

    const [forceShowAge, setForceShowAge] = useState(0);//for the popup 0 = normal, 1 hide, 2 show
    const [forceShowTerms, setForceShowTerms] = useState(0);//for the popup 0 = normal, 1 hide, 2 show
    const [contestState, setContestState] = useState("loading");

    useMessage('show-settings-popup', (send, payload) => {
        if(!isInEditor)
            return;
        if (payload.popup === "age-verify") {
            if (payload.action === "hide")
                setForceShowAge(1);
            else
                setForceShowAge(2);
        }
        if (payload.popup === "terms-verify") {
            if (payload.action === "hide")
                setForceShowTerms(1);
            else
                setForceShowTerms(2);
        }
    });

    const add_iframe_resizer = () => {
        if (document.getElementById("iframe-resizer")?.length)
            return;
        let script = document.createElement("script");
        script.src = '/assets/js/iframeResizer.contentWindow.min.js';
        script.id = "iframe-resizer-script";
        document.head.appendChild(script);
    }
    const [locationAllowed, setLocationAllowed] = useState(false);
    const countDownComplete = () => {
        get_contest();
    }


    if (contestState === "loading")
        return (<div className={'contest-body'} id={`classic-contest-${id}`}><img
            src='https://app.rewardsfuel.com/assets/images/loader.svg'/></div>)
    if (!locationAllowed) {
        if(!isInEditor)
            return (<ContestNotAllowedInLocation/>);
    }
    const viewId = "4145919326";
    const projectId = "337294783";
    window.parent.postMessage('ContestLoaded', '*');//tells parent window contest is loaded
    ReactGA.initialize("G-Y2T36CDB4W", {
        'projectId': projectId,
        'viewId': viewId,
        'debug': true,
        'property_id': id //todo test GA4 setting custom dimensions
    });
    ReactGA.send("pageview");
    let sortOrder = contest?.layout?.elements_order ? contest.layout?.elements_order : ["title", "images", "entryMethods", "countdown", "tabs", "score", "bottomBar"];
    let contestElements = [
        <ContestTitle sortName={"title"} contest={contest}/>,
        <ContestImages sortName={"images"} contest={contest}/>,
        <EntryMethods contestUrl={contestUrl} sortName={"entryMethods"} {...props} />,
        <Countdown endDate={contest.end_time} onCountdownComplete={countDownComplete} sortName={"countdown"} contest={contest}/>,
        <ContestTabs sortName={"tabs"} contest={contest}/>,
        <ContestScore sortName={"score"} user_id={user_id} contest={contest}/>,
        <ContestBottomBar sortName={"bottomBar"} set_user_id={set_user_id} user_id={user_id} contest={contest}/>];
    const sortedContestElements = sortContestElements(sortOrder, contestElements);


    if (contestState === "pre")
        return (<PreContestPage get_contest={get_contest} contestUrl={contestUrl} contest={contest} setContestState={setContestState}/>);
    if (contestState === "post")
        return (<PostContestPage contest={contest} setContestState={setContestState}/>);
    return (
        <div className={"container contest-container"}>
            <div className={'contest-body'} id={`classic-contest-${id}`}>
                {sortedContestElements}
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <PoweredByRf isInEditor={isInEditor} contest={contest}/>
            <PreContestChecksAge forceShowAge={forceShowAge} contest={contest} /*age and terms restrictions*/  />
            <PreContestChecksTerms forceShowTerms={forceShowTerms} contest={contest} /*age and terms restrictions*/  />
        </div>
    );

}


