import React, { useState, useEffect } from 'react';
import {req, store} from '../CommonLibrary';

const NewsletterEntryDoubleOpt = () => {
    const [confirmationMessage, setConfirmationMessage] = useState(<div className={"p-4"}>Loading...</div>);
    let mounted = false;
    useEffect(() => {
        if(mounted) return;
        mounted = true;
        const params = new URLSearchParams(window.location.search);
        const env = params.get('env');
        const contest_id = params.get('contest_id');
        if(!env || !contest_id) {
            return  setConfirmationMessage("sorry your link isn't right!");
        }
        const url = `/contest/confirm_newsletter_entry/?contest_id=${contest_id}&env=${env}`;
        req('GET', url).then(response => {
            if(response.jwt){
                store.set('jwt', response.jwt,60);
            }
            if(response.success)
                setConfirmationMessage(response.success);
            setTimeout(() => {
                let location = `${response.redirect}#_rf3=showPoints`;
                window.location.href = (location);
            }, 2000);
        });
    }, []);


    return (
        <div style={{ textAlign: 'center', marginTop: '100px' }}>
            <p>{confirmationMessage}</p>
        </div>
    );
};

export default NewsletterEntryDoubleOpt;
