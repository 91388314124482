import { log } from '../../../CommonLibrary';

export default function PreContestChecksGeo(props) {

    const { contest} = props;
    const { restrictions } = contest;

    let client_local = contest?.client?.geo;
    // Return true if there are no geo restrictions
    if (!restrictions?.geo || !client_local) return true;
    if(restrictions.geo.locations.length === 0) return true;
    try {
        let allowed = restrictions.geo.allow_or_block === 'allowed';
        let clientIsInList = false;

        // Check if the client's location is in the allowed or blocked list
        for (const location of restrictions.geo.locations) {
            // Check if the location is a city
            if (location.types.includes('locality')) {
                clientIsInList = client_local.city === location.structured_formatting.main_text;
            }
            // Check if the location is a state or province
            else if (location.types.includes('administrative_area_level_1')) {
                clientIsInList = client_local.region === location.structured_formatting.main_text;
            }
            // Check if the location is a country
            if (location.types.includes('country') && location.terms[0].value === client_local.country) {
                clientIsInList = true;
                break;
            }
            if (clientIsInList) break;
        }
        // Return the result based on whether the client's location was found in the allowed or blocked list
        return allowed ? clientIsInList : !clientIsInList;
    } catch (e) {
        log('PreContestChecksGeo error', e);
    }
}
