import {useEffect} from "react";

export default function EmbedFrame(props) {
    function isLoadedScript(lib) {
        return document.querySelectorAll('[src="' + lib + '"]').length > 0
    }
    const getQueryParams = () => {
        // Get the query string of the current page
        const queryString = window.location.search;
        // Split the query string into an array of individual key-value pairs
        const queries = queryString.slice(1).split('&');
        // Convert the array of key-value pairs into an object
        const params = Object.fromEntries(queries.map(q => q.split('=')));
        // Return the params object
        return params;
    }
    let height = window.innerHeight-115;
    let width = window.innerWidth-50;
    const params = getQueryParams();
    const url = params?.url;
    let type = params.type;
// Load library
    function loadScript(lib) {
        if (isLoadedScript(lib)) {
            return
        }
        let script = document.createElement('script');
        script.setAttribute('src', lib);
        document.getElementsByTagName('body')[0].appendChild(script);
        return script;
    }
    let mounted = false;
    useEffect(() => {
        if (!mounted) {
            mounted = true;
            height = window.innerHeight-115;
            width = window.innerWidth-50;
            if(type==="pinterest")
                loadScript('https://assets.pinterest.com/js/pinit.js');
            else if(type==="youtube")
                loadScript('https://apis.google.com/js/platform.js');
        }
    }, []);
    if(height<50)
        height=50;
    if(type==="pinterest")
        return(<div style={{ padding: 0, margin : '0 auto', textAlign: 'center' }}><a data-pin-do="embedUser" data-pin-scale-width="60" data-pin-board-width={width} data-pin-scale-height={height}  href={url}></a></div>);
    if(type==="youtube") {
        let channelId = params.accountId;
        console.log("channelId",channelId);
        return(<div className="g-ytsubscribe" data-channelid={channelId} data-layout="default"
                    data-count="default"></div>);
    }

}
