import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Privacy from "./pages/Privacy";
import About from "./pages/About";
import ClassicContest from "./ContestTypes/ClassicContest/ClassicContest";
import {log} from "./CommonLibrary";
import WhoAreYouAndGo from "./ContestTypes/ClassicContest/WhoAreYouAndGo";
import EmbedFrame from "./pages/EmbedFrame";
import NewsletterEntryDoubleOpt from "./pages/NewsletterEntryDoubleOpt";
import WinnersClaimPage from "./pages/WinnersClaimPage";
import ContestantPasswordReset from "./pages/ContestantPasswordReset";
function App() {
  try {
      return (
          <div className="contest-host">
              <BrowserRouter>
                  <Routes>
                      <Route path="/" element={<About/>}/>
                      <Route path="/privacy/*" element={<Privacy/>}/>
                      <Route path="/c/:id" element={<ClassicContest/>}/>
                      <Route path="/contest/embed_and_go*" element={<WhoAreYouAndGo/>}/>
                      <Route path="/contest/embed/*" element={<EmbedFrame/>}/>
                      <Route path="/contest/newsletter_double_opt/*" element={<NewsletterEntryDoubleOpt/>}/>
                        <Route path="/contestant-reset-password/*" element={<ContestantPasswordReset/>}/>
                      <Route path="/winners/*" element={<WinnersClaimPage />}/>
                  </Routes>
              </BrowserRouter>
          </div>
      );
  }catch (e) {
      log("App.js error", e);
  }
}

export default App;
