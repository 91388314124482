import React, {useEffect, useState} from "react";
import {log} from "../../../CommonLibrary";

export default function Countdown(props) {
    //todo add end of contest trigger
    const {contest, endDate, onCountdownComplete} = props
    const [showClock, setShowClock] = useState(true);

    let details = props.contest;
    let count_down = {
        years: 0,
        days: 0,
        hours: 0,
        min: 0,
        sec: 0,
    };
    const [timeRemaining, SetTimeRemaining] = useState(count_down);
    let cur_time = Math.floor(new Date().getTime() / 1000)
    let clock_interval = false;
    useEffect(() => {
        try {
            if (details.end_time === 0 || endDate < cur_time || details.layout.hide_countdown) {
                console.log("contest over or hide clock");
                return onCountdownComplete();
            } else {
                if (!clock_interval) {
                    clearInterval(clock_interval);
                }
                clock_interval = setInterval(() => {
                    SetTimeRemaining(calculate_countdown(endDate));
                }, 1000);
            }
            return () => {
                clearInterval(clock_interval);
            }
        } catch (e) {
            log("Countdown useEffect error", e);
        }
    }, [endDate]);
    const calculate_countdown = (end_date) => {
        try {
            let diff = end_date / 1000 - Math.floor(new Date() / 1000);
            // clear countdown when date is reached
            if (diff <= 0) return false;
            let timeLeft = {
                years: 0,
                days: 0,
                hours: 0,
                min: 0,
                sec: 0,
            };
            // calculate time difference between now and expected date
            if (diff >= 365.25 * 86400) {
                // 365.25 * 24 * 60 * 60
                timeLeft.years = Math.floor(diff / (365.25 * 86400));
                diff -= timeLeft.years * 365.25 * 86400;
            }
            if (diff >= 86400) {
                // 24 * 60 * 60
                timeLeft.days = Math.floor(diff / 86400);
                diff -= timeLeft.days * 86400;
            }
            if (diff >= 3600) {
                // 60 * 60
                timeLeft.hours = Math.floor(diff / 3600);
                diff -= timeLeft.hours * 3600;
            }
            if (diff >= 60) {
                timeLeft.min = Math.floor(diff / 60);
                diff -= timeLeft.min * 60;
            }
            timeLeft.sec = Math.floor(diff);
            return timeLeft;
        } catch (e) {
            log("Countdown error", e);
        }
    }

    function add_leading_zeros(value) {
        value = String(value);
        while (value.length < 2) {
            value = "0" + value;
        }
        return value;
    }

    if (contest.layout.hide_clock || endDate < new Date().getTime()) {
        return '';
    } else {
        return (
            <div id='countdown-clock' className="countdown">
                {timeRemaining.days > 0 &&
                    <span className="countdown-col">
                      <span className="countdown-col-element">
                        <span className={'number'}>{add_leading_zeros(timeRemaining.days)}</span>
                        <span>{timeRemaining.days === 1 ? "Day" : "Days"}</span>
                      </span>
                    </span>
                }
                <span className="countdown-col">
                  <span className="countdown-col-element">
                    <span className={'number'}>{add_leading_zeros(timeRemaining.hours)}</span>
                    <span>Hours</span>
                  </span>
                </span>
                <span className="countdown-col">
                  <span className="countdown-col-element">
                    <span className={'number'}>{add_leading_zeros(timeRemaining.min)}</span>
                    <span>Min</span>
                  </span>
                </span>
                <span className="countdown-col">
                      <span className="countdown-col-element">
                        <span className={'number'}>{add_leading_zeros(timeRemaining.sec)}</span>
                        <span>Sec</span>
                      </span>
                </span>
            </div>
        );
    }

}
