import EntryMethod from "./EntryMethod";
import {log, req} from "../../../CommonLibrary";
import { useEffect, useState } from "react";



export default function EntryMethods(props){

    const {contest} = props;
    const [whoAreYouSchema,setWhoAreYouSchema] = useState({})
    const [whoAreYouUx,setWhoAreYouUx] = useState({});
    const getWhoAreYou = async () => {
        let url = `/contest/get_who_are_you_form/?contest_id=${contest.contest_id}`;
        let response = await req("GET",url);
        setWhoAreYouSchema(response.schema);
        setWhoAreYouUx(response.ux);
    }
    let entryMethodsMounted = false;
    useEffect(() => {
        if(entryMethodsMounted)return;
        entryMethodsMounted = true;
        getWhoAreYou();
    },[]);

    if(contest.layout?.hide_entry_methods)
        return (<div id="no-entry-methods-showing"></div>);

    try {
        let entry_methods = false;
        try {
            entry_methods = props.contest.entry_methods;
        } catch (e) {
            log("EntryMethods getting entry methods error", e);
            return '';
        }
        entry_methods.sort((a,b) => a.sort_order - b.sort_order);//sort entry methods by sort_order
        if (!entry_methods)
            return '';
        let rows = [];
        let StyleModifications = contest.layout?.entry_method_style;
        let args = {
            whoAreYouSchema,
            whoAreYouUx,
            ...props
        };
        for (let i = 0; i < entry_methods.length; i++) {
            if(parseInt(entry_methods[i].entry_type) >0)
                rows.push(<EntryMethod StyleModifications={StyleModifications} key={"entry-method-"+entry_methods[i].entry_method_id} {...args} entry_method={entry_methods[i]}/>);
        }
        return (<div id="entry-methods-container" className={'entry-methods-container'}>{rows}</div>);
    }catch (e) {
        log("EntryMethods error", e);
    }
}
