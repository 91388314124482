import {store} from "../../../../CommonLibrary";

export default function EmbedAndGo(props) {
    const {entry_method,contest} = props;
    document.addEventListener('click', function (event) {

        // If the clicked element doesn't have the right selector, bail
        if (!event.target.matches('.embed-and-go-link')) return;
        function popupwindow(url, title, w, h) {
            const win = window;
            const y = win.top.outerHeight / 2 + win.top.screenY - ( h / 2);
            const x = win.top.outerWidth / 2 + win.top.screenX - ( w / 2);
            return win.open(url, title, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);

        }
        let link = event.target.attributes.href.value;
        let width = window.innerWidth * .8;
        let height = window.innerHeight * .8;
        try{
            popupwindow(link,"Enter the contest",width,height);
            event.preventDefault();
        }catch (e) {
            return true;
        }
        // Don't follow the link



    }, false);
    let url = `/contest/embed_and_go/?contest_id=${contest.contest_id}&emi=${entry_method.entry_method_id}&url=${encodeURIComponent(entry_method.url)}`;
    return(<>
        <div className="entry-method-description">{entry_method.description}</div>
        <div className="entry-method-embed"
            dangerouslySetInnerHTML={{__html: entry_method.embed_code}}
        />
        <div className="entry-method-button-holder"><a href={url} rel="noreferrer" target="_blank" className="btn action-button w-100 embed-and-go-link">Enter now</a></div>
    </>)
}
