import { Button, Grid, TextField } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import app.css
import "../App.css";
import { form_obj,req,store } from "../CommonLibrary";
export default function ContestantPasswordReset() {
    let url = new URL(window.location.href);
    let token = url.searchParams.get("token");
    let contestId = url.searchParams.get("c");
    let email = url.searchParams.get("email");
    let redirect = url.searchParams.get("redirect");
    if(!token || !contestId || !email){
        return (<>Invalid request</>);
    }
    const handleForgotPassword = async (e) => {
        e.preventDefault();
        let pass1 = document.getElementById("sign-in-email-1").value;
        let pass2 = document.getElementById("sign-in-email-2").value;
        if(pass1 !== pass2){
            toast("Passwords do not match");
            return;
        }
        if(pass1.length < 8){
            toast("Password must be at least 8 characters");
            return;
        }
        let data = form_obj(e.target);
        //todo show loading
        let response = await req("POST", "/contest/contestant_reset_password", data);
        if(response.error){
            toast(response.error);
        }else{
            toast("Password reset successfully");
            store.set( `c_${contestId}_jwt`, response.jwt);
            setTimeout(() => {
            if(redirect)
                window.location.href = redirect;
            else
                window.location.href = "/c/"+contestId;
            }, 3000);
         
        }
    }
    return (
        <div container={1} className="forgot-pass-form">

                <h1>Password Reset</h1>
                <form onSubmit={handleForgotPassword}>
                    <input type={"hidden"} name={"token"} value={token} />
                    <input type={"hidden"} name={"contest_id"} value={contestId} />
                    <input type={"hidden"} name={"email"} value={email} />
                    <TextField id="sign-in-email-1" name="newPass"  label="Password" variant="outlined" type="password" required={1} />
                    <TextField id="sign-in-email-2" label="Password Verify password" variant="outlined" type="password" required={1} />
                    <Button variant="contained" fullWidth={1} type="submit">Reset</Button>
                </form>
                <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}