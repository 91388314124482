import {log, req, store} from "../../../CommonLibrary";
import ReactDOM from "react-dom/client";
//import {ReactFormGenerator} from "react-form-builder2";
//import 'react-form-builder2/dist/app.css';
import React, {useEffect,useState} from "react";
import NewsletterEntry from "./EntryTypeRenderrers/NewsletterEntry";
import ShareEntry from "./EntryTypeRenderrers/ShareEntry";
import EmbedAndGo from "./EntryTypeRenderrers/EmbedAndGo";
import WatchToWin from "./EntryTypeRenderrers/WatchToWin";
import { JsonForms } from '@jsonforms/react';
import {
    materialCells,
    materialRenderers,
} from '@jsonforms/material-renderers';
import { Button } from "@mui/material";
import { toast } from "react-toastify";
let submitData = {};
let formReady = false;
let whoAreYouCallback = null;
export default function EntryMethod(props) {
    //this component sets up the button and hide/show of the button the button body is determined by the render type
    const {StyleModifications, entry_method,user_id,set_user_id, contest, whoAreYouSchema,whoAreYouUx } = props;
    const [showWhoAreYou, setShowWhoAreYou] = useState(false);
    const ShowWhoForm = (callback) => {
        console.log("show who form",callback);
        setShowWhoAreYou(true);
        whoAreYouCallback = callback;
    }
    let entryMethodBody= [];
   

    const toggleEntryMethod = async(event) => {
        let entryMethodId = event.currentTarget.getAttribute("data-id");
        let other_holders = document.querySelectorAll(".entry-method-holder");
        let entryMethodButtons = document.querySelectorAll(".btn-entry-method");
        entryMethodButtons.forEach((element) => {
            element.classList.remove("active-entry-method-button");
        });
        event.currentTarget.classList.add("active-entry-method-button");
        setShowWhoAreYou(false);
        other_holders.forEach((element) => {
            element.classList.remove("show");
        })
        let holder = document.getElementById(`entry-method-holder-${entryMethodId}`);
        if (!holder)
            return;
            whoAreYouCallback =null;
        holder.classList.toggle("show");
    }
  
    if (entry_method.renderer === "newsletterEntry")
        entryMethodBody =[<NewsletterEntry key={entry_method.entry_method_id} {...props}/>];
    if (entry_method.renderer === "ShareEntry")
        entryMethodBody =[<ShareEntry  ShowWhoForm={ShowWhoForm} key={entry_method.entry_method_id} {...props}/>];
    if (entry_method.renderer === "EmbedAndGo")
        entryMethodBody =[<EmbedAndGo  key={entry_method.entry_method_id}  {...props}/>];
    if (entry_method.renderer === "WatchToWin")
        entryMethodBody =[<WatchToWin  key={entry_method.entry_method_id} ShowWhoForm={ShowWhoForm}  {...props}/>];

        
    let button_style = {};
    let font_style = {};
    let entry_points_font_style = {};
    if (StyleModifications?.border_radius)
        button_style.borderRadius = StyleModifications.border_radius + "rem";
    if (StyleModifications?.font_size) {//default is 1.25rem we'll use the number passed in as a multiplier
        font_style.fontSize = 1.25 * StyleModifications.font_size + "rem";
        entry_points_font_style.fontSize = .8 * StyleModifications.font_size + "rem";
    }
    let col_1_style = {};
    let col_2_style = {};
    let col_3_style = {};
    if (StyleModifications?.show_icon === false || StyleModifications?.show_label === false || StyleModifications?.show_points === false) {

        let hiddenColumns = 0;
        Object.entries(StyleModifications).forEach(([key, value]) => {
            let cols_we_care_about = ["show_icon", "show_label", "show_points"];
            if ((cols_we_care_about.includes(key) && value === false)) {
                hiddenColumns++;
            }
        });

        if (hiddenColumns === 1) {
            //cases if (middle column is hidden) {col_1_style.width = "50%";col_3_style.width = "50%";}
            if (StyleModifications?.show_label === false) {
                col_1_style.flex = "0 0 50%";
                col_1_style.maxWidth = "50%";
                col_2_style.display = "none";
                col_3_style.flex = "0 0 50%";
                col_3_style.maxWidth = "50%";
            }
            //if (left column is hidden) {col_2_style.width = "83%";col_3_style.width = "17%";}
            if (StyleModifications?.show_icon === false) {
                col_1_style.display = "none";
                col_2_style.flex = "0 0 83%";
                col_2_style.maxWidth = "83%";
                col_3_style.flex = "0 0 17%";
                col_3_style.maxWidth = "17%";
            }
            if (StyleModifications?.show_points === false) {
                col_1_style.flex = "0 0 17%";
                col_1_style.maxWidth = "17%";
                col_2_style.flex = "0 0 83%";
                col_2_style.maxWidth = "83%";
                col_3_style.display = "none";
            }
            //if (right column is hidden) {col_1_style.width = "17%";col_2_style.width = "83%";}
        }
        if (hiddenColumns === 2) {
            if (StyleModifications?.show_label === false && StyleModifications?.show_icon === false) {
                col_1_style.display = "none";
                col_2_style.display = "none";
                col_3_style.flex = "0 0 100%";
                col_3_style.maxWidth = "100%";
            }
            if (StyleModifications?.show_label === false && StyleModifications?.show_points === false) {

                col_1_style.flex = "0 0 100%";
                col_1_style.maxWidth = "100%";
                col_3_style.display = "none";
                col_2_style.display = "none";
            }
            if (StyleModifications?.show_icon === false && StyleModifications?.show_points === false) {
                col_1_style.flex = "0 0 100%";
                col_1_style.maxWidth = "100%";
                col_2_style.display = "none";
                col_3_style.display = "none";
            }
        }

    }
    const whoAreYouHandler = async(event) => {
        event.preventDefault();
        let formData = submitData;
        console.log("whoAreYouHandler",formData);
        if(formData.name.length < 3){
            toast.error("Name must be at least 3 characters");
            return;
        }
        if(formData.email.length < 3){
            toast.error("Email must be at least 3 characters");
            return;
        }
        let data = {
            contest_id: contest.contest_id,
            who_are_you:formData
        };
        //todo show loading
        let loader = document.getElementById(`entry-method-loader-${entry_method.entry_method_id}`);
        loader.classList.add("show");
        let response = await req("POST", "/contest/contestant_who_are_you", data);
        if(response.jwt){
            store.set(`c_${contest.contest_id}_jwt`, response.jwt, 60);
            if(whoAreYouCallback){
                await whoAreYouCallback(response.jwt);
                loader.classList.remove("show");
            }
        }
        if(response.error)
            toast.error(response.error);
    }

    const handeFormUpdate = (args) => {
        const {data, errors} = args;
        errors.length === 0 ? formReady = true : formReady = false;
        submitData = data;
    }

    return (
        <div className={'entry-method'} id={'entry-method-' + entry_method.entry_method_id}>
            <button className="btn-entry-method" style={button_style} data-id={entry_method.entry_method_id}
                    onClick={toggleEntryMethod}>
                <div className="btn-entry-method-icon" style={{...font_style, ...col_1_style}}>
    
                    {entry_method.icon && <div className="svg-em-icon" dangerouslySetInnerHTML={{__html: entry_method.icon}} />}
                </div>
                <div className="btn-entry-method-name"
                     style={{...font_style, ...col_2_style}}>{entry_method.button_label}</div>
                <div className="btn-entry-method-points" style={{...font_style, ...col_3_style}}>
                    <div className="btn-entry-method-points-number">{entry_method.entry_points}</div>
                    <div className="btn-entry-method-points-label" style={entry_points_font_style}>entries</div>
                </div>
            </button>
            <div className={'entry-method-holder'} id={'entry-method-holder-' + entry_method.entry_method_id}>
                <div className={'entry-method-loader'} id={'entry-method-loader-' + entry_method.entry_method_id}>
                    <img
                        src='https://app.rewardsfuel.com/assets/images/loader.svg'/></div>
                
                     {showWhoAreYou ?
                        <div className={'entry-method-who-are-you'}>
                        <form onSubmit={whoAreYouHandler}>
                            <JsonForms
                                schema={whoAreYouSchema}
                                uischema={whoAreYouUx}
                                renderers={materialRenderers}
                                cells={materialCells}
                                validationMode={'ValidateAndShow'}
                                onChange={handeFormUpdate}
                                onSubmit={whoAreYouHandler}
                            />
                            <div className={"pt-4"} >
                                                    <Button fullWidth={true} variant={"outlined"} type="submit"
                                                    >Enter contest
                                                    </Button>
                                                </div>
                            </form>
                     </div>:
                        <div className={'entry-method-primary-state'}
                            id={'entry-method-primary-state-' + entry_method.entry_method_id}>{entryMethodBody}</div>
                     }

            </div>
        </div>
    )
}
