import {log, req} from "../../../CommonLibrary";
import {useState,useEffect} from "react";
import useMessage from "@rottitime/react-hook-message-event";

export default function ContestTabs(props) {
    const {contest} = props
    const[detailsContent,setDetailsContent]=useState("Loading...");
    const[rulesContent,setRulesContent]=useState("Loading...");
    useMessage('flip-tab', (send, payload) => {
        toggle_tabs();
    });
    let mounted = false
    async function get_tabbed_data(){
        setDetailsContent(await req('GET',`/contest/get_content/?contest_id=${contest.contest_id}&t=details`));
        setRulesContent( await req('GET',`/contest/get_content/?contest_id=${contest.contest_id}&t=rules`));
    }

    useEffect(() => {
        if(!mounted) {
            mounted = true;
            get_tabbed_data()
        }
    }, []);


        let details = props.contest;

        if (details.layout.hide_tabs)
            return '';


        const toggle_tabs = (event) => {
            try {
                let buttons = document.querySelectorAll('.tabs-details-area .nav-link');
                buttons.forEach((element) => {
                    element.classList.toggle("active");
                })
                let tabs = document.querySelectorAll('.tabs-details-area .tab-pane');
                tabs.forEach((element) => {
                    element.classList.toggle("active");
                })
            } catch (e) {
                log("ContestTabs toggle_tabs error", e);
            }
        }

        return (
            <div className={'tabs-details-area'}>
                <div className="row">
                    <div className="col-6">
                        <button className="nav-link active" id={"tabs-details-button"}  aria-current="details" onClick={toggle_tabs}>{contest?.layout?.tab_details||"Details"}
                        </button>
                    </div>
                    <div className="col-6">
                        <button className="nav-link" id={"tabs-rules-button"} onClick={toggle_tabs}>{contest?.layout?.tab_rules||"Rules"}</button>
                    </div>
                </div>
                <div className="details-tabbed-content">
                    <div className="tab-pane active" id="tab-content-details" role="tabpanel"
                         aria-labelledby="home-tab">
                        <div dangerouslySetInnerHTML={{ __html: detailsContent }} />
                    </div>
                    <div className="tab-pane" id="tab-content-rules" role="tabpanel"
                         aria-labelledby="profile-tab">
                        <div dangerouslySetInnerHTML={{ __html: rulesContent }} />
                    </div>
                </div>
            </div>
        )


}
