import HoverPop from "./HoverPop";

export default function PoweredByRf(props) {
    const {contest,isInEditor} = props;
    if(contest.hide_powered_by_rf) return null;
    //todo get background color from contest and set the link color to show
    let containerStyle = {
        padding: '1rem',
        fontSize: '.6rem',
        disable:'block !important'
    }
    let linkStyle = {
        textDecoration: 'none',
    }
    let referral_link = "https://rewardsfuel.com/?rc="+contest.contest_id;
    if(isInEditor){
        return(<HoverPop hoverValue={"Remove this upgrading your plan."}><div style={containerStyle}><a href={referral_link} style={linkStyle} target={"_blank"}>Rewards Fuel Contest</a></div></HoverPop>);
    }
    return(<div style={containerStyle}><a href={referral_link} style={linkStyle} target={"_blank"}>Rewards Fuel Contest</a></div>)
}
