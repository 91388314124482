import {log} from "../../../CommonLibrary";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/navigation";
import "swiper/css/pagination";
export default function ContestImages(props) {
    try {
        let details = props.contest;
        if (details.layout.hide_images || !details.layout.header_images.length)
            return '';
        if (details.layout.header_images.length === 1)
            return (
                <div  key={"slider-1"} className="header-image"><img  key={"img-1"}  className="img-fluid" src={details.layout.header_images[0]}/></div>
            );
        if (details.layout.header_images.length > 1) {

            let images = [];
            for (let i = 0; i < details.layout.header_images.length; i++) {
                images.push( <SwiperSlide key={"slider-"+i}><img key={"img-"+i} className="img-fluid" src={details.layout.header_images[i]}/></SwiperSlide>);
            }
            return (
                <Swiper
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                    slidesPerView={1}
                    cssMode={true}
                    navigation={true}
                    pagination={true}
                    mousewheel={true}
                    keyboard={true}
                >
                    {images}
                </Swiper>
            );
        }
    } catch (e) {
        log("ContestImages error", e);
    }
}
