import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { form_obj, req, store } from "../../../../CommonLibrary";

let player = null;

export default function WatchToWin(props) {
    const { entry_method, user_id, contest,ShowWhoForm} = props;

    const addYtVideoScr = () => {
        if (document.getElementById("yt-video-scr"))
            return false;
        let tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        tag.id = "yt-video-scr";
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }


    function getYoutubeVideoId(url) {
        var myURL = new URL(url);
        var videoId = myURL.searchParams.get("v");
        return videoId;
    }

    function player_state_change(event) {
        if (event.data === 0) {
            start_entry()
        }
    }


    const startVideo = () => {
        try {
            let videoId = getYoutubeVideoId(entry_method.video_url);
            let targetHeight = window.innerHeight * .7;
            function player_ready(event) {
                setTimeout(() => {
                    event.target.playVideo();
                }, 200);

            }
            var YT;//todo need to remove this is only added to get passed the React compiler
            player = new YT.Player('watch-entry-method-' + entry_method.entry_method_id, {
                height: `${targetHeight}px`,
                width: '100%',
                videoId: videoId,
                host: 'https://www.youtube.com',
                playerVars: {
                    controls: 1,
                    disablekb: 0,
                    iv_load_policy: 0,
                    modestbranding: 1,
                    playsinline: 1,
                    autoPlay: 1,
                    showinfo: 0,
                    showRelatedVideos: 0,
                    origin: window.location.href,
                    fs: 1,
                    enablejsapi: 1,
                    rel: 0
                },
                events: {
                    'onStateChange': player_state_change,
                    'onReady': player_ready
                }
            });
        } catch (e) {
            console.log(e);
        }


    }
    async function start_entry_signed_in(submit_user) {
        console.log("start entry signed in", submit_user);
        let data = {
            contest_id: contest.contest_id,
            entry_method_id: entry_method.entry_method_id,
            user_id: submit_user,
            entry_method_type: entry_method.entry_method_type,
        };
        try{
        document.getElementById(`entry-method-loader-${entry_method.entry_method_id}`).classList.add("show");
        }catch(e){}
        let response = await req("POST", "/contest/enter_contest", data);
        if (response.entry_points) {
            //hide entry method
            let targetNode = document.getElementById(`entry-method-${entry_method.entry_method_id}`).remove();
            toast.success("You have entered the contest");
        } else {
            toast.error(response.message);
        }
        return  true
    }

    function start_entry() {
        if (!user_id) {
            ShowWhoForm(start_entry_signed_in);
            return;
        } else {
            start_entry_signed_in(user_id);
        }
    }
    const lookForOpenOrclose = () => {
        let targetNode = document.getElementById(`entry-method-holder-${entry_method.entry_method_id}`);
        if (targetNode.classList.contains("show") && player === null) {
            startVideo();
        } else if (!targetNode.classList.contains("show") && player !== null) {
            player.stopVideo();
            player.destroy();
            player = null;
        }
    }


    let mounted = false;
    useEffect(() => {
        if (mounted) return;
        mounted = true;
        addYtVideoScr();
        setInterval(() => {
            lookForOpenOrclose();
        }, 500);

    }, []);
   
   
    return (<div>
        <div className="entry-method-description"
            dangerouslySetInnerHTML={{ __html: entry_method.description }} />
        <div className="watch-to-win-video" id={`watch-parent-${entry_method.entry_method_id}`}>
            <div id={`watch-entry-method-${entry_method.entry_method_id}`}></div>
        </div>
    </div>);
}