import {log, req} from "../../../CommonLibrary";
import {useEffect,useState} from "react";

export default function ContestScore(props) {
        const {contest,user_id} = props;
        const [total_entries,set_total_entries] = useState(0);
        const [contestant_entries,set_contestant_entries] = useState(0);
        let interval;
        const poll_score=async ()=> {
            try{clearInterval(interval);}catch (e) {}
            //interval = setInterval(async ()=>{
                let url = `/contest/get_score/?contest_id=${contest.contest_id}`;
                if(user_id?.length && user_id!==null)
                    url += `&jwt=${user_id}`;
                let result = await req("GET",url)
                set_total_entries(result.contest_entries);
                set_contestant_entries(result.contestant_entries);
            //},5000)//todo re-enable or use sockets
        }
        let scoreMounted = false;
        useEffect(() => {
            if(scoreMounted)
                return;
            scoreMounted = true;
            poll_score();
        },[])
        if (contest.layout.hide_score)
            return '';
        let col_number = 'col-6';
        if (contest.layout?.hide_your_entries || contest.layout?.hide_total_entries)
            col_number = 'col-12';
        return (
            <div className={'row entries-summary-row'}>
                {!contest.layout?.hide_your_entries &&
                <div className={col_number+" your-entries-column"}>
                    <div className={'your-entries-number'}>{contestant_entries}</div>
                    <div className={'your-entries-wording'}>{contest?.layout?.your_entries_label||"Your Entries"}</div>
                </div>
                }
                {!contest.layout?.hide_total_entries &&
                <div className={col_number+" total-entries-column"}>
                    <div className={'total-entries-number'}>{total_entries}</div>
                    <div className={'total-entries-wording'}>{contest?.layout?.total_entries_label||"Total Entries"}</div>
                </div>}
            </div>
        )

}
