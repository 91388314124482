import { store} from "../../../CommonLibrary";
import {useState} from "react";
import {toast} from "react-toastify";
import { JsonForms } from '@jsonforms/react';
import {materialRenderers} from "@jsonforms/material-renderers";

export default  function PreContestChecksAge(props) {
    const {forceShowAge} = props;
    const contest_id = props.contest.contest_id;
    const [ageVerified,setAgeVerified] =useState(false);
    const fifteenYearsAgo = new Date();
    fifteenYearsAgo.setFullYear(fifteenYearsAgo.getFullYear() - 15);
    const formattedDate = fifteenYearsAgo.toISOString().slice(0, 10);
    const [birthDate, setBirthDate] = useState({
        "date": formattedDate//todo update to dynamic age 15 years ago
    });

    if(forceShowAge===1) {
        let popup = document.getElementById("age-verified");
        if(popup){
            popup.remove();
            document.querySelector('body')?.classList.remove("modal-open");
        }

        return;//hide popup
    }
    let age_verified = store.get(`age_verified_${contest_id}`);
    let restrictions = props.contest.restrictions;
    //way to force either popopup
    const handeAgeVerify = (event) => {
        event.preventDefault();
        function get_age(birth_date) {
            let today = new Date();
            let birthDate = new Date(birth_date);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
        if (get_age(birthDate.date) > restrictions.age_verify) {
            setAgeVerified(true);
            store.set(`age_verified_${contest_id}`, true, 30);
        } else {
            toast(`Sorry you must be ${restrictions.age_verify} to enter.`);
        }

    }
    let birthday_picker_schema = {
        "properties": {
            "date": {
                "type": "string",
                "format": "date",
                "description": "schema-based date picker"
            }
        }
    }
    let birthday_data = {
        "date": new Date().toISOString().split('T')[0]
    }
    const showAgePopup = ()=>{
        document.querySelector('body')?.classList.add("modal-open");
        return (<>
            <div className="modal fade show" id={"age-modal"} tabIndex="-1" role="dialog">
                <div className=" modal-dialog" role=" document">
                    <form onSubmit={handeAgeVerify}>
                        <div className=" modal-content">
                            <div className=" modal-header">
                                <h5 className=" modal-title">Verify your age.</h5>
                            </div>
                            <div className=" modal-body">
                                <JsonForms
                                    schema={birthday_picker_schema}
                                    data={birthDate}
                                    renderers={materialRenderers}
                                    onChange={({ errors, data }) => setBirthDate(data)}
                                />
                            </div>
                            <div className=" modal-footer">
                                <button type="submit" className=" btn btn-primary">Verify age</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>);
    }

    if(forceShowAge===2) {
        return showAgePopup();
    }


    if (restrictions.age_verify) { //one condition at a time first age and then terms
        if (!ageVerified && !age_verified) {
            //form settings
            return showAgePopup();
        }
    }

}
