import {log, req, store} from "../../../CommonLibrary";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import { JsonForms } from '@jsonforms/react';
import {materialRenderers} from "@jsonforms/material-renderers";
let modalRemoved = false;
export default  function PreContestChecksTerms(props) {
    const {forceShowTerms,contest} = props;
    const [agreedToTerms,setAgreedToTerms] = useState(false);
    const terms_init = {
        title:'Agree to terms first',
        body:'Loading terms...',
        button:"Agree"
    }
    const [terms,setTerms] = useState(terms_init);
    const contest_id = props.contest.contest_id;
    async function get_terms(){
        let url = '/contest/get_content/?t=terms&contest_id='+contest_id;
        let termsContent = await req("GET",url);
        if(termsContent.trim()==="")
            termsContent = "Add your terms here...";
        let new_terms = terms;
        new_terms.body = termsContent;
        setTerms(new_terms);

    }
    let mounted = false
    useEffect (() => {
        if(!mounted){
            get_terms()
            mounted = true;
        }
    },[]); //get terms
    //way to force either popopup
    const handeldAreedToTerms=()=>{
        setAgreedToTerms(true);
        store.set(`agreed_to_terms_${contest_id}`,true,30);
    }
    const showTermsPopup = ()=>{
        document.querySelector('body')?.classList.add("modal-open");
        return (<div id="terms-verified-modal">
                <div className="modal fade show"   tabIndex="-1" role="dialog">
                    <div className=" modal-dialog" role=" document">
                        <form >
                            <div className=" modal-content">
                                <div className=" modal-header">
                                    <h5 className=" modal-title">{terms.title}</h5>
                                </div>
                                <div className="terms-modal modal-body">
                                    <div dangerouslySetInnerHTML={{ __html: terms.body }} />
                                </div>
                                <div className=" modal-footer">
                                    <button type="button"  onClick={handeldAreedToTerms} className=" btn btn-primary">{terms.button}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
            </div>
        );
    }
    //for editing purposes force show or hide
    if(forceShowTerms===1) {
        let popup = document.getElementById("terms-verified-modal");
        if(popup && !modalRemoved){
            modalRemoved = true;
            try {
                popup.innerHTML = "";
                document.querySelector('body')?.classList.remove("modal-open");
            }catch (e) {
                console.log("error removing terms popup",e);
            }
        }
        return <></>;
    }
    if(forceShowTerms===2)
        return showTermsPopup();
    //agreed too terms
    let agreed_to_terms = store.get(`agreed_to_terms_${contest_id}`);
    if (!contest.restrictions.agreed_to_terms || agreed_to_terms || agreedToTerms)
        return <></>;
    //else show terms
    return showTermsPopup();

}
