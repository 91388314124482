import Container from "@mui/material/Container";
import {req} from "../CommonLibrary";
import React, {useEffect, useState} from "react";
import {JsonForms} from "@jsonforms/react";
import {materialCells, materialRenderers} from "@jsonforms/material-renderers";
import {Button} from "@mui/material";
import {toast, ToastContainer} from "react-toastify";

export default function WinnersClaimPage(props) {
    const [message,setMessage] = useState(<h4>loading...</h4>);
    const [schema,setSchema] = useState({});
    const [ux,setUx] = useState({});
    const [loading,setLoading] = useState(false);
    const [showForm,setShowForm] = useState(false);
    const [showPrizeClaim,setShowPrizeClaim] = useState(false);
    const [contest_id,setContest] = useState('');
    const [winner_id,setWinner] = useState(false);
    const getQueryStringVariables = (search) => {
        const variables = {};
        const pairs = search.slice(1).split('&');
        for (const pair of pairs) {
            const [key, value] = pair.split('=');
            variables[decodeURIComponent(key)] = decodeURIComponent(value);
        }
        return variables;
    }

    const currentUrl = new URL(window.location.href);
    const handlePrizeClaim = async () => {
        let name = claimData?.name;
        if(!name?.length){
            toast.error("Please enter your name");
            return;
        }
        let address = claimData?.address;
        if(!address?.length){
            toast.error("Please enter your address");
            return;
        }
        setLoading(true);
        setShowForm(false);
        let valid_address = await validateAddress(address);

        if(valid_address !==false) {
            let url = `/contest/claim_prize/`;
            let data = {
                name,
                address,
                GoogleAddress:valid_address,
                contest_id,
                winner_id
            }
            let response = await req("POST", url, data);
            if (response.result) {
                setLoading(false);
                setMessage(<h1 className={"text-center"}>{response.message}</h1>);
                setShowForm(false);
                setShowPrizeClaim(false);
            }else{
                setLoading(false);
                setShowForm(true);
                setShowPrizeClaim(true);
                toast.error(response.message);
            }
        }else{
            setLoading(false);
            setShowForm(true);
            setShowPrizeClaim(true);
            toast.error("Invalid address");
        }
    }
    const validateAddress = async (address) => {
        const GOOGLE_MAPS_API_KEY = 'AIzaSyDQd-ltfhq_IwzYVGPeEqJuiPWDzMMqhEk'
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_MAPS_API_KEY}`);
        const data = await response.json();
        if(data.status === "OK" && data.results.length){
            return data.results[0].formatted_address;
        }
        return false;
    }
    const [claimData,setClaimData] = useState({});
    const handleGetContestData = async (event) => {
        setClaimData(event.data)
    }
    const startDownload = async (url) => {
        //show loading
        //hide button
    }


    const getWinnerData= async (contest_id,env)=>{
        let url = `/contest/get_prize_claim/?contest_id=${contest_id}&env=${env}`;
        let response = await req("GET",url);
        if(response.result){
            setContest(response.contest_id);
            setWinner(response.winner_id);
            if(response.prize_claim_form){
                setMessage(<h1 className={"text-center"}>{response.message}</h1>);
                setSchema(response.prize_claim_form.schema);
                setUx(response.prize_claim_form.ux);
                setShowPrizeClaim(true);
                setShowForm(true);
            }else{
                //downloadable prize
                setMessage(<>
                    <h1 className={"text-center"}>{response.message}</h1>
                    <Button download={true} variant={"outlined"} fullWidth={true} href={response.download_link} onClick={startDownload}>Download your prize</Button></>);
            }
        }else{
            //show some error
            setMessage(<h1 className={"text-center"}>{response.message}</h1>);
        }
        //cases prize already claimed, no token or new contest id, prize ready to be claimed
    }
    const addStyle = () => {
        let css = `
        .shipping-address{
        padding:1rem;
        }.text-center{text-align:center}
        .shipping-address input{
        margin-bottom:1.5rem;
        }
        .shipping-address textarea{
        min-height:5rem;
        margin-bottom:1.5rem;
        }
        .winners-button{
        padding-top:1rem;
        }
        `;
        document.querySelector("head").insertAdjacentHTML("beforeend",`<style>${css}</style>`);
    }
    let mounted = false;
    useEffect(() => {
       if(mounted)return;
         mounted = true;
        addStyle();
        const variables = getQueryStringVariables(currentUrl.search);
        if(!variables.contest_id || !variables.env) {
            setMessage("Sorry, wrong link");
            return;
        }
        const {contest_id,env} = variables;
       getWinnerData(contest_id,env);

    }, []);

    return(<Container className={"text-center p-4"}>

        {message}
        {showPrizeClaim && <div className={'shipping-address container'}>
            {loading && <div className={'loader'}><img src="https://app.rewardsfuel.com/assets/images/loader.svg"/></div>}
            {showForm &&
                <><div sx={{maxWidth:"900px",paddingBottom:"2rem"}}>
                    <JsonForms
                        schema={schema}
                        uischema={ux}
                        onChange={handleGetContestData}
                        renderers={materialRenderers}
                        cells={materialCells}
                        validationMode={'ValidateAndShow'}
                        onSubmit={handlePrizeClaim}

                    />
                </div>
                    <div className={"winners-button"} >
                        <Button fullWidth={true} variant={"outlined"} type="button" onClick={handlePrizeClaim}
                        >Claim prize
                        </Button>
                    </div>
                </>
            }
        </div>}
        <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            />
    </Container>);
}
