import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {form_obj, req, store} from "../../../../CommonLibrary";
import {ButtonGroup, FormControlLabel, FormGroup, Grid, Switch} from "@mui/material";
import {toast} from "react-toastify";

let previous_email = false;
export default function NewsletterEntry(props) {
    const {contest, entry_method, user_id, contestUrl} = props;
    const [status, setStatus] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [showCheckbox, setShowCheckbox] = useState(entry_method.require_checkbox);
    const [doubleOptInSent, setDoubleOptInSent] = useState(false);

    async function fetchStatus() {
        const url = `/contest/get_entry_state/?contest_id=${contest.contest_id}&entry_method_id=${entry_method.entry_method_id}`;
        toggleLoader();
        const res = await req('GET', url);

        setStatus(res.state);
        toggleLoader();
        if (res.state === 'started') {
            previous_email = res.email;
            setName(res.name);
            setEmail(res.email);
            setDoubleOptInSent(true);
        }
        if (res.state === 'not_started' && entry_method.showCheckbox) {
            setShowCheckbox(true);
        }
    }
    const resendVerification = async () => {
        const url = `/contest/newsletter_entry_resend_verification/`;
        //show loading
        const data = {
            contest_id: contest.contest_id,
            entry_method_id: entry_method.entry_method_id,
            email: email,
            contest_url: contestUrl
        };
        toggleLoader()
        const res = await req('POST', url, data);
        toggleLoader();
        if (res.success) {
            toast.success('Verification email sent');
        } else {
            toast.error(res.error);
        }
    }
    const toggleLoader = () => {
        let elm = document.getElementById('entry-method-loader-' + entry_method.entry_method_id);
        if (elm.classList.contains('show'))
            elm.classList.remove('show');
        else
            elm.classList.add("show");
    }
    async function handleSubmit(event) {
        event.preventDefault();

        const body = {name, email};
        if (entry_method.showCheckbox) {
            body.confirm = event.target.confirm.checked;
        }
        let data = form_obj(event.target);
        if (entry_method.require_checkbox && !data.confirm_subscription) {
            toast.error("You must confirm that you want to receive our newsletter");
            return false;
        }
        data.contest_id = contest.contest_id;
        data.entry_method_id = entry_method.entry_method_id;
        data.redirect_url = contestUrl;
        toggleLoader();
        const url = `/contest/enter_contest/`
        const res = await req('POST', url, data);

        //couple possible options, we get a new contestant so we update the local storage
        if (res.user) {
            console.log('updating user', contest.contest_id, res.user);
            store.set(`c_${contest.contest_id}_jwt`, res.user.jwt);
        }
        toggleLoader();
        //we get an updated state for the current entry method state relative to the contest;
        if (res.state === 'started') {
            setStatus('started');
            setDoubleOptInSent(true);
        }
    }
    const handleUpdate = async (event) => {
        event.preventDefault();
        let form_data = form_obj(event.target);
        if (entry_method.require_checkbox && !form_data.confirm_subscription) {
            toast.error("You must confirm that you want to receive our newsletter");
            return false;
        }
        form_data.contest_id = contest.contest_id;
        form_data.entry_method_id = entry_method.entry_method_id;
        form_data.redirect_url = contestUrl;//todo add variable if embedded to redirect to parent page
        const url = `/contest/update_newsletter_entry/`;
        toggleLoader();
        const res = await req('POST', url, form_data);
        toggleLoader();
        if (res.result) {
            toast.success('Updated, please check your email for a verification email');
            setName(form_data.name);
            setEmail(form_data.email);
            setStatus('started');
        } else {
            toast.error(res.error);
        }

    }
    async function handleDoubleOptIn() {
        //todo this isn't working
        const res = await req('POST', `/api/contests/${contest.contest_id}/entry_methods/${entry_method.entry_method_id}`);
        if (res.status === 'double_opted_in') {
            setStatus('double_opted_in');
        }
    }
    let mounted = false;
    useEffect(() => {
        if (!mounted) {
            mounted = true;
            if (user_id)
                fetchStatus();
            else
                setStatus('not_started');
        }
    }, []);
    const getStatusHtml = (status) => {
        if (status === 'not_started') {
            return (
                <form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        <div className="col-sm-6">
                            <TextField required={true} fullWidth={true} name={"name"} id="name" label="Name"
                                       variant="outlined" value={name}
                                       onChange={event => setName(event.target.value)}/>
                        </div>
                        <div className="col-sm-6">
                            <TextField required={true} name={"email"} type={"email"} fullWidth={true} id="email"
                                       label="Email" variant="outlined" value={email}
                                       onChange={event => setEmail(event.target.value)}/>
                        </div>
                        {showCheckbox && (
                            <Grid item={true} fullWidth={true} xs={12}>
                                <FormGroup className={"d-block w-100 text-center"}>
                                    <FormControlLabel control={<Switch name={"confirm_subscription"}/>}
                                                      label="Confirm subscription"/>
                                </FormGroup>
                            </Grid>
                        )}
                        <div className="col-12">
                            <button className={"btn btn-primary btn-lg w-100 mt-3 mb-3"}>
                                Enter contest
                            </button>
                        </div>
                    </div>
                </form>
            );
        }

        // other code removed for brevity

        if (status === 'started') {
            return (
                <div>
                    <b>Your entry is waiting for you to confirm your email.</b>
                    {doubleOptInSent ? (
                        <>
                            <Grid container={true}>
                                <Grid item={true} xs={12}>
                                    A verification email has been sent to {email}. Please check your inbox and follow
                                    the instructions to confirm your entry. If you need to resend the email or correct a
                                    mistake, click the appropriate link.
                                </Grid>

                                <Grid item={true} xs={12}>
                                    <ButtonGroup fullWidth={true} variant="outlined"
                                                 aria-label="outlined primary button group">
                                        <Button fullWidth={true} className={"btn action-button btn-lg w-100 mt-3 mb-3"} onClick={() => setStatus('mistake')}>
                                            Wrong email address? Click here to modify it.
                                        </Button>
                                        <Button fullWidth={true} className={"btn action-button btn-lg w-100 mt-3 mb-3"} onClick={() => resendVerification()}>
                                            Re-send verification email
                                        </Button>
                                    </ButtonGroup>

                                </Grid>

                            </Grid>

                        </>
                    ) : (
                        <>
                            <Button fullWidth={true} className={"btn action-button btn-lg w-100 mt-3 mb-3"} onClick={handleDoubleOptIn}>
                                Resend double opt-in email
                            </Button>
                        </>
                    )}
                </div>
            );
        }
        if (status === "mistake") {
            return (
                <form onSubmit={handleUpdate}>
                    <input type={"hidden"} name={"previous_email"} value={previous_email}/>
                    <div className={"row"}>
                        <div className="col-sm-6">
                            <TextField required={true} fullWidth={true} name={"name"} id="name" label="Name"
                                       variant="outlined" value={name}
                                       onChange={event => setName(event.target.value)}/>
                        </div>
                        <div className="col-sm-6">
                            <TextField required={true} name={"email"} type={"email"} fullWidth={true} id="email"
                                       label="Email" variant="outlined" value={email}
                                       onChange={event => setEmail(event.target.value)}/>
                        </div>
                        {showCheckbox && (
                            <Grid item={true} fullWidth={true} xs={12}>
                                <FormGroup className={"d-block w-100 text-center"}>
                                    <FormControlLabel control={<Switch name={"confirm_subscription"}/>}
                                                      label="I confirm to subscribing"/>
                                </FormGroup>
                            </Grid>
                        )}
                        <div className="col-12">
                            <button className={"btn action-button btn-lg w-100 mt-3 mb-3"}>
                                Update entry and send verification email
                            </button>
                        </div>
                    </div>
                </form>
            )
        }
        if (status === 'double_opted_in') {
            return <div>Your entry has been confirmed!</div>;
        }
    }
    return getStatusHtml(status)
}
