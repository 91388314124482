import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {form_obj, req} from "../../../CommonLibrary";
import {useEffect, useState} from "react";
import "../../../App.css";
import {Paper} from "@mui/material";
export default function PostContestPage(props) {
    //
    const {contest, contestUrl, get_contest} = props;
    const [notified, setNotified] = useState(false);
    const [ShowWinners, setShowWinners] = useState(contest.show_winners);
    const [winners, setWinners] = useState([]);
    const getWinners = async () => {
        let result =await req("GET", "/contest/get_post_contest_winners/?contest_id=" + contest.contest_id);
        console.log("getWinners", result);
        if(result.length)
            formatWinners(result);
    }
    const handleContestantNotification = (e) => {
        e.preventDefault();
        let data = form_obj(e.target);
        data.contest_id = contest.contest_id
        req("POST", "/contest/notify_post_contest_contestant/", data);
        setNotified(true);
        //send email to server
    }
    const formatWinners = (winnerData) => {
        let elmsArray = [];
        for (const winner of winnerData) {
            elmsArray.push(
                <Paper className={"p-2 m-2"} key={winner.winning_entry}>
                    <div className={"p-2"}>Winner: {winner.name}</div>
                    <div className={"p-2"}>Prize: {winner.prize}</div>
                    <div className={"p-2"}>Winning Entry: {winner.winning_entry}</div>
                </Paper>)
        }
        setWinners(elmsArray);
    }
    let mounted = false;
    useEffect(() => {
        if (!mounted) {
            mounted = true;
            if(ShowWinners)
                getWinners();
        }
    }, []);

    return(<>
        <div className={"container text-center"}>
        <h1>Contest is over</h1>
        {!notified ? <>
                <h2 className={"p-2"}>Get notified about our next contest</h2>
                <form className={"p-3"} onSubmit={handleContestantNotification}>
                    <TextField name="email" fullWidth={true} id="outlined-basic" label="Email" required={true}
                               type={"email"}
                               variant="outlined"/>
                    <Button fullWidth={true} typeof={"submit"} className={"mt-4"} variant={"outlined"} type={"submit"}>Notify
                        me</Button>
                </form>
            </> :
            <h2 className={"p-2"}>You'll be notified.</h2>}
        {ShowWinners && <><h2>Winners</h2>
                {winners}</>
        }
        </div></>)
}
