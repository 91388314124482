import react, { useEffect, useState } from "react";
import { req, store } from "../../CommonLibrary";
import { toast, ToastContainer } from "react-toastify";

import { JsonForms } from '@jsonforms/react';
import {
    materialCells,
    materialRenderers,
} from '@jsonforms/material-renderers';
import React from "react";
import { Button, Grid } from "@mui/material";
import Container from '@mui/material/Container'

export default function WhoAreYouAndGo() {
    //used for when the person entering the contest isn't logged into the contest yet
    const [schema, setSchema] = useState({})
    const [ux, setUx] = useState({});
    const [loading, setLoading] = useState(false);
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
    let contest_id = 0;
    let entry_method_id = 0;
    let redirect = '';
    try {
        contest_id = params.contest_id;
        entry_method_id = params.emi;
        redirect = params.url;
    } catch (e) {
        console.log("error getting contest_id", e);
    }
    //const [whoAreYouForm, setWhoAreYouForm] = useState(false);

    //render a who are you page
    //get who are you from contest
    //render in a form in page
    //submit form to contest
    //redirect without referrer

    let submitData = {};
    let formReady = false;

    const formHandler = async (event) => {
        if (formReady) {
            let post_data = {
                contest_id: contest_id,
                entry_method_id: entry_method_id,
                who_are_you: submitData,
            }
            setLoading(true);
            let heading = document.getElementById("headline");
            let initialText = heading.innerHTML;
            heading.innerHTML = "Loading..";
            let url = `/contest/enter_contest/`;
            let response = await req("POST", url, post_data);
           
            if (response.jwt) {
                //contest monitors change in stored value
                store.set(`c_${contest_id}_jwt`, response.jwt, 60);
            }
            setLoading(false);
            heading.innerHTML = "Redirecting..";
            if (response.entry_points) {
                toast(`You're entered to win! +${response.entry_points} entries`);
                setShowForm(false);
                //todo add some extra outgoing message?
                //todo sendMessage to opener to update entries methods and show message maybe
            }
            setTimeout(() => {
                window.location.href = redirect;
            }, 1500);
            //show message
            //redirect
        } else {
            return toast(`Please fill out the form`);
        }
    }
    const handeFormUpdate = (args) => {
        const { data, errors } = args;
        errors.length === 0 ? formReady = true : formReady = false;
        submitData = data;
    }
    const [showForm, setShowForm] = useState(false);
    const getWhoAreYou = async () => {
        let url = `/contest/get_who_are_you_form/?contest_id=${contest_id}&entry_method_id=${entry_method_id}`;
        let response = await req("GET", url);
        if(!response.schema || !response.ux){
            if(response.url){
                window.location.href = response.url;
            }else{
                if(redirect!==""){
                    window.location.href = redirect;
                }
            }
        }
        setSchema(response.schema);
        setUx(response.ux);
        setShowForm(true);
    }
    let pageMounted = false;

    useEffect(() => {

        if (pageMounted || contest_id === 0 || entry_method_id === 0) {
            pageMounted = true;
            if ((contest_id === 0 || entry_method_id === 0) && redirect !== '')
                window.location.href = redirect;
            return;
        }
        getWhoAreYou();
    }, []);
    const add_css = (url, id) => {
        //todo remove main css or color css if previewing
        if (document.getElementById(id))
            document.getElementById(id).remove();
        let css = document.createElement('link');
        css.rel = 'stylesheet';
        css.media = 'all';
        css.id = id;
        css.href = url;
        document.getElementsByTagName('head')[0].appendChild(css);
        if (id === "css-preview" || id === "classic-contest-css") {
            document.getElementById("cc-style").remove();
        }
        if (id === "css-preview")
            document.getElementById("cc-style").remove();
    }
    add_css('https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css', 'bootstrap-css');
    return (<Container sx={{ maxWidth: "700px", padding: "1rem" }}>
        <Grid container={true} display="flex" align="center" verticalAlign="middle" justifyContent="center" alignItems="center" >

            <Grid item={true} xs={12}>
                <h1 id="headline" className={'mt-4 mb-4 text-center'}>Who are you? We need to know in case you win.</h1>

                <div className={'container'}>
                    {loading && <div className={'loader'}><img src="https://app.rewardsfuel.com/assets/images/loader.svg" /></div>}
                    {showForm &&
                        <><div sx={{ maxWidth: "900px", paddingBottom: "2rem" }}>
                            <JsonForms
                                schema={schema}
                                uischema={ux}
                                renderers={materialRenderers}
                                cells={materialCells}
                                validationMode={'ValidateAndShow'}
                                onChange={handeFormUpdate}
                                onSubmit={formHandler}

                            />
                        </div>
                            <div className={"pt-4"} >
                                <Button fullWidth={true} variant={"outlined"} type="button" onClick={formHandler}
                                >Enter contest
                                </Button>
                            </div>
                        </>
                    }
                </div>
            </Grid>
        </Grid>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={true}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        /></Container>
    )
}
