import {log} from "../../../CommonLibrary";

export default function ContestTitle(props) {
    try {
        let details = props.contest;
        if (details.layout.hide_title)
            return '';
        return (
            <div className={'contest-title'}>{details.contest_name}</div>
        )
    } catch (e) {
        log("ContestTitle error", e);
    }
}
