import {Grid, IconButton} from "@mui/material";
import {
    EmailShareButton, FacebookMessengerShareButton,
    FacebookShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useState} from "react";
import "../../../App.css";
import {form_obj, req} from "../../../CommonLibrary";
import Countdown from "./Countdown";

export default function PreContestPage(props) {
    const {contest, contestUrl, get_contest} = props;
    const [notified, setNotified] = useState(false);
    const handleContestantNotification = (e) => {
        e.preventDefault();
        let data = form_obj(e.target);
        data.contest_id = contest.contest_id
        req("POST", "/contest/notify_pre_contest_contestant/", data);
        setNotified(true);
        //send email to server
    }
    const countDownComplete = () => {
        console.log("countdown complete pre contest page");
        get_contest();//
    }
    const [shareSubject, setShareSubject] = useState('Contest starting soon');//todo add settings
    const [shareBody, setShareBody] = useState('Check out this contest, it starts soon!');//todo add settings
    return (<Grid fullWidth={true} container={true} className={"container text-center"}>
        <Grid item={true} xs={12}><h1>Contest starts soon</h1></Grid>
        <Grid className={"p-3"} item={true} xs={12}>
            <Countdown endDate={contest.start_time} onCountdownComplete={countDownComplete} sortName={"countdown"}
                       contest={contest}/>
        </Grid>
        <Grid item={true} xs={12}>
            {!notified ? <>
                    <h2 className={"p-2"}>Get notified when this starts</h2>
                    <form className={"p-3"} onSubmit={handleContestantNotification}>
                        <TextField name="email" fullWidth={true} id="outlined-basic" label="Email" required={true}
                                   type={"email"}
                                   variant="outlined"/>
                        <Button fullWidth={true} typeof={"submit"} className={"mt-4"} variant={"outlined"} type={"submit"}>Notify
                            me</Button>
                    </form>
                </> :
                <h2 className={"p-2"}>You'll be notified.</h2>}
        </Grid>
        <Grid className={"pt-4"} item={true} xs={12}>
            <h4>Share</h4>
            <div className={'pre-contest-share-buttons'}>
                <EmailShareButton url={contestUrl} subject={shareSubject}
                                  body={shareBody}><EmailIcon size={32} round={true}/></EmailShareButton>
                <FacebookShareButton url={contestUrl} quote={shareBody}><FacebookIcon size={32}
                                                                                      round={true}/></FacebookShareButton>
                <FacebookMessengerShareButton url={contestUrl} appId={"123456789"}><FacebookMessengerIcon size={32}
                                                                                                          round={true}/></FacebookMessengerShareButton>
                <TwitterShareButton url={contestUrl} title={shareBody}><TwitterIcon size={32}
                                                                                    round={true}/></TwitterShareButton>
                <RedditShareButton url={contestUrl} title={shareBody}><RedditIcon size={32}
                                                                                  round={true}/></RedditShareButton>
                <WhatsappShareButton url={contestUrl} title={shareBody}><WhatsappIcon size={32}
                                                                                      round={true}/></WhatsappShareButton>
                <TelegramShareButton url={contestUrl} title={shareBody}><TelegramIcon size={32}
                                                                                      round={true}/></TelegramShareButton>
                <CopyToClipboard text={contestUrl}
                                 onCopy={() => this.setState({copied: true})}>
                    <IconButton varian={"contained"} color={"primary"} className={"pre-contest-copy-button"}><ContentCopyIcon size={32}
                                                                                       round={true}/></IconButton>
                </CopyToClipboard>
            </div>
        </Grid>

    </Grid>)
}
