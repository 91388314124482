import {form_obj, log, req, store} from "../../../CommonLibrary";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import {toast} from "react-toastify";
import {GoogleLogin} from 'react-google-login';

export default function ContestBottomBar(props) {
    let {user_id, set_user_id, contest} = props;
    const [showSignInModal, setShowSignInModal] = useState(false);
    const [showComplaintModal, setShowComplaintModal] = useState(false);
    const [complaintTitle, setComplaintTitle] = useState("");
    const [complaintInstructions, setComplaintInstructions] = useState("");
    const [loginBtn,setLoginBtn] = useState("");
    const [showModalLoader, setShowModalLoader] = useState(0);
    const handleCloseComplaintModal = () => setShowComplaintModal(false);
    const handleCloseSignInModal = () => setShowSignInModal(false);
    const showComplainModal = (bug) => {
        setShowModalLoader(0);
        if (bug) {
            setComplaintTitle("Bug Report");
            setComplaintInstructions("Please describe the bug you found");
        } else {
            setComplaintTitle("Complaint");
            setComplaintInstructions("Please describe the problem you encountered");
        }
        setShowComplaintModal(true);
    }
    const sign_out = () => {
        store.del(`c_${contest.contest_id}_jwt`);
        set_user_id(null);
    }
    const sign_in = () => {
        //show sign in modal
        setShowSignInModal(1);
        setShowModalLoader(0);
    }
    const handleSignIn = async (event) => {
        event.preventDefault();
        let data = form_obj(event.target);
        data.contest_id = contest.contest_id;
        //show loading
        setShowModalLoader(1);
        let result = await req("POST", "/contest/contestant_sign_in/", data);
        setShowModalLoader(0);
        if (result.success) {
            store.set(`c_${contest.contest_id}_jwt`, result.jwt);
            set_user_id(result.user_id);
            handleCloseSignInModal();
        } else {
            toast.error(result.error);
        }
    }
    const handleForgotPassword = async (event) => {
        event.preventDefault();
        let email = document.getElementById("sign-in-email").value;
        if (!isValidEmail(email)) {
            document.getElementById("sign-in-email").focus();
            return toast.error("Please add your email address");
        }
        //show loading
        setShowModalLoader(1);
        let data = {
            contest_id: contest.contest_id,
            email: email
        }
        let result = await req("POST", "/contest/contestant_forgot_password/", data);
        setShowModalLoader(0);
        setShowSignInModal(0);
        if (result.success) {
            toast.success(result.success);
        } else {
            toast.error(result.error);
        }
    }

    function isValidEmail(email) {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        return emailRegex.test(email);
    }

    const handleGripe = async (event) => {
        event.preventDefault();
        let data = form_obj(event.target);
        data.contest_id = contest.contest_id;
        data.user_id = user_id;
        data.event_type = complaintTitle;
        setShowModalLoader(1)
        let result = await req("POST", "/contest/contestant_complaint/", data);
        setShowModalLoader(0);
        if (result.success) {
            setShowComplaintModal(false);
            toast.success(result.success);
        } else {
            toast.error(result.error);
        }
    }
    const responseGoogle = async (response) => {
        console.log("handle google response", response);
        if(!response.tokenId)
            return;
        setShowModalLoader(1);
        let data = {
            contest_id: contest.contest_id,
            google_response: response
        }
        let result = await req("POST", "/contest/contestant_google_sign_in/", data);
        setShowModalLoader(0);
        if (result.success) {
            store.set(`c_${contest.contest_id}_jwt`, result.jwt);
            set_user_id(result.user_id);
            handleCloseSignInModal();
        } else {
            toast.error(result.error);
        }

        handleCloseSignInModal();
    }
    let login_btn = (<button type={'button'} onClick={sign_in} className={'btn btn-link'}>Sign in</button>)
    if (user_id !== null)
        login_btn =(<button onClick={sign_out} type={'button'} className={'btn btn-link'}>Sign out</button>);

    try {
        let details = props.contest;
        if (details.layout.hide_bottom_bar)
            return '';
        return (<>
        <div className="bottom-bar">
            {contest.layout?.show_login &&
                login_btn
            }
            {contest.layout?.show_privacy &&
                <button type={'button'}  onClick={()=>window.open("/privacy")} target={"_blank"} className={'btn btn-link'}>Privacy</button>
            }
            {contest.layout?.show_bug &&
                <button type={'button'} onClick={() => showComplainModal(1)} className={'btn btn-link'}>Bug</button>
            }
            <button type={'button'} onClick={() => showComplainModal(0)} className={'btn btn-link'}>Inappropriate
            </button>
        </div>
        <Modal show={showSignInModal} onHide={handleCloseSignInModal}>
            {showModalLoader===1 && <div className={'loader modal-loader'}><img src={'/assets/images/loader.svg'}/></div>}
            <form id={'sign-in-form'} onSubmit={handleSignIn}>
                <Modal.Header closeButton>
                    <Modal.Title>Sign in</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TextField fullWidth={true} className={"mt-2 mb-2"} id="sign-in-email" label="email" type={"email"}
                               required={true} variant="outlined"/>
                    <TextField id="sign-in-pass" label="password" fullWidth={true} className={"mt-2 mb-2"}
                               type={"password"} required={true} variant="outlined"/>

                    <Button variant={"text"} fullWidth={true} className={"mt-2 mb-2"} type={'button'}
                            onClick={handleForgotPassword}>Forgot pass?</Button>
                    <GoogleLogin
                        clientId="765768859466-n9adgj5cks37eu177e3lk99ur7t6csd4.apps.googleusercontent.com"
                        buttonText="Sign in with Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        fullWidth={true}
                        className={"w-100 text-center"}
                        cookiePolicy={'single_host_origin'}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSignInModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" type={"submit"}>
                        Sign in
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
        <Modal show={showComplaintModal} onHide={handleCloseComplaintModal}>
        {showModalLoader===1 && <div className={'loader modal-loader'}><img src={'/assets/images/loader.svg'}/></div>}
            <form id={'complaint-form'} onSubmit={handleGripe}>
                <Modal.Header closeButton>
                    <Modal.Title>{complaintTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {complaintInstructions}
                    <TextField multiline
                               rows={7} fullWidth={true} className={"mt-2 mb-2"} id="complaint" name="complaint"
                               label="Details" type={"text"} required={true} variant="outlined"/>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseComplaintModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" type={"submit"}>
                        Submit
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    </>
    )
    } catch (e) {
        log("ContestBottomBar error", e);
    }
}
